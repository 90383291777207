<template>
  <v-checkbox
    v-model="model"
    :value="input.value || true"
    :label="$translation.t(input.label)"
    hide-details="auto"
  ></v-checkbox>
</template>

<script>
export default {
  name: "CheckboxInput",
  props: {
    input: {
      type: Object,
      required: true,
    },
    value: { default: false },
  },
  data: () => ({
    model: null,
  }),
  mounted() {
    this.model = this.value ?? this.input.default;
  },
  watch: {
    model(newInput) {
      this.$emit("input", newInput);
    },
  },
};
</script>

<style scoped></style>
